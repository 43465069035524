<template>
  <div id="detail" class="container">

    <template v-if="loading">
      <div class="row">
        <!-- 圖片放大 -->

        <div class="col-sm-5 col-md-5 col-lg-5">
          <div class="sp-img_area">
            <zoom-on-hover class="zoompro-border" :img-normal="zoomImage" scale="2">

            </zoom-on-hover>
            <swiper :options="swiperOption" ref="mySwiper" v-if="data.pics.length > 0">
              <!-- slides -->
              <swiper-slide v-for="(item, idx) in data.pics" :key="idx" @click.native="changeImage(idx)">
                　　<img :src="item" alt="" />

              </swiper-slide>
              <!-- Optional controls -->
              <div class="swiper-button swiper-button-prev iconfont icon-left" slot="button-prev"></div>
              <div class="swiper-button swiper-button-next iconfont icon-right" slot="button-next"></div>
            </swiper>
          </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7">
          <div class="sp-area">
            <div class="sp-nav">
              <div class="sp-content">
                <div class="sp-heading">
                  <h2>
                    <a href="javascript:void(0)">{{ data.spuName | zxby }}</a>
                  </h2>
                </div>
                <div class="price-box">
                  <span class="new-price" v-if="data.wholesaleNum==quantity">€{{ data.wholesale }}</span>
                  <span class="new-price" v-else>€{{ skus.price }}</span>
                  <span class="old-price" style=" margin-left: 20px;

                     color: #e01212;" v-if="data.originPrice > 0">€{{ data.originPrice || 10 }}</span>
                </div>
                <!-- <div v-if="data.wholesale!=0" style="color: #e01212; font-weight:bold;">
                  A partir de <span style="color: #e01212;">{{data.wholesaleNum}}</span> unidades: €{{data.wholesale}}

                </div> -->

                <div class="product-desc">
                  <!-- <p>详细描述</p> -->
                </div>
                <div class="product-size_box" v-if="false">
                  <span>Size</span>
                  <select class="myniceselect nice-select" style="display: none;">
                    <option value="1" v-for="s in size">{{ s }}</option>
                  </select>
                  <div @click="openSelect" class="nice-select myniceselect">
                    <span class="current">{{ size[sizeIndex] }}</span>
                    <ul class="list">
                      <li v-for="(s, idx) in size" @click="changeSelect(idx)" data-value="1" :key="idx" :class="{
                          selected: sizeIndex == idx,
                          focus: sizeIndex == idx
                        }" class="option">
                        {{ s }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="in-stock">
                  <!-- <i class="iconfont icon-dui"></i><span>{{ skus.stock }} In Stock</span> -->
                  <span>Refrencia: {{ data.sn }}</span>
                </div>
                <div class="quantity">
                  <label style="width: 92px">Por Unidad </label>
                  <div class="cart-plus-minus">
                    <input class="cart-plus-minus-box" v-model="quantity" disabled type="text" />
                    <div @click="quantityChange(0)" class="dec qtybutton">
                      <i class="iconfont icon-down"></i>
                    </div>
                    <div @click="quantityChange(1)" class="inc qtybutton">
                      <i class="iconfont icon-up"></i>
                    </div>
                  </div>
                  <span style="font-weight: bold">€{{ skus.price }}</span>
                </div>
                <div class="quantity">
                  <label>Por Paquete</label>
                  <div class="cart-plus-minus">
                    <input class="cart-plus-minus-box" disabled v-model="batchNum" type="text" />
                    <div @click="batchNumChange(0)" class="dec qtybutton">
                      <i class="iconfont icon-down"></i>
                      
                    </div>
                    <div @click="batchNumChange(1)" class="inc qtybutton">
                      <i class="iconfont icon-up"></i>
                    </div>
                  </div>
                  <span style="font-weight: bold">€{{ data.wholesale }}</span>
                </div>
                <div class="additional-btn_area">
                    <span class="additional_btn" @click="addCart">{{ $i18n.ADD_TO_CART }}</span>
                  </div>
                <!-- 这里不要的 -->
                <div class="category-list_area" v-if="true">
                  <h6>Descripción:</h6>
                  <ul class="tags-list">
                    <li>
                      <a href="javascript:void(0)">{{data.intro}}</a>
                    </li>

                  </ul>
                </div>

                <!-- 
                  标签
                <div class="category-list_area tag-list_area" v-if="data.labels.length > 0">
                  <h6>{{ $i18n.TAGS }}:</h6>
                  <ul class="tags-list">
                    <li v-for="item in data.labels" :key="item.labelId">
                      <a href="javascript:void(0)" data-id="item.labelId">{{ item.labelName }}</a>
                    </li>
                  </ul>
                </div> -->
                <!-- 不要的 分享到其他网站-->
                <div v-if="false" class="torress-social_link">
                  <h6>Shere This product:</h6>
                  <ul>
                    <li class="facebook">
                      <a href="https://www.facebook.com" data-toggle="tooltip" target="_blank" title=""
                        data-original-title="Facebook">
                        <i class="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li class="twitter">
                      <a href="https://twitter.com" data-toggle="tooltip" target="_blank" title=""
                        data-original-title="Twitter">
                        <i class="fab fa-twitter-square"></i>
                      </a>
                    </li>
                    <li class="youtube">
                      <a href="https://www.youtube.com" data-toggle="tooltip" target="_blank" title=""
                        data-original-title="Youtube">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li class="google-plus">
                      <a href="https://www.plus.google.com/discover" data-toggle="tooltip" target="_blank" title=""
                        data-original-title="Google Plus">
                        <i class="fab fa-google-plus"></i>
                      </a>
                    </li>
                    <li class="instagram">
                      <a href="https://rss.com" data-toggle="tooltip" target="_blank" title=""
                        data-original-title="Instagram">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="sp-tab_area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="sp-product-tab_nav">
                <div class="product-tab">
                  <ul class="nav product-menu">
                    <li>
                      <a @click="changeTabs" class="active" data-toggle="tab"
                        data-href="#description"><span>{{ $i18n.Description }}</span></a>
                    </li>
                    <li v-if="data.comments.total">
                      <a @click="changeTabs" data-toggle="tab" data-href="#reviews"><span>{{ $i18n.Reviews }}
                          ({{ data.comments.total }})</span></a>
                    </li>
                  </ul>
                </div>
                <div class="tab-content torress-tab_content">
                  <div id="description" class="tab-pane active show" role="tabpanel">
                    <div class="product-description" v-html="zxby(data.content)">
                      <!--zxby(data.content)-->
                      <p class="short-desc">
                        short-desc
                      </p>
                      <p class="additional-desc">
                        additional-desc
                      </p>
                    </div>
                  </div>
                  <div id="reviews" class="tab-pane" role="tabpanel">
                    <div class="tab-pane active" id="tab-review">
                      <form class="form-horizontal" id="form-review">
                        <div id="review">
                          <table class="table table-striped table-bordered">
                            <tbody>
                              <template v-if="data.comments.total > 0" v-for="(cmt, idx) in data.comments.list"
                                :data-id="cmt.orderCommentId">
                                <tr :key="cmt.orderCommentId">
                                  <td style="width: 50%;">
                                    <strong>{{ cmt.userName }}</strong>
                                  </td>
                                  <td class="text-right">
                                    {{ cmt.orderCreateTime }}
                                  </td>
                                </tr>
                                <tr :key="cmt.orderCommentId + idx">
                                  <td colspan="2">
                                    <!-- 内容 -->
                                    <p>{{ cmt.content }}</p>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                        <page-component :page-config="pageConfigTotal" @changeCurrentPage="changePage"></page-component>
                        <template v-if="false">
                          <h2>Write a review</h2>
                          <div class="form-group required">
                            <div class="col-sm-12 p-0">
                              <label>Your Email <span class="required">*</span></label>
                              <input class="review-input" type="email" name="con_email" id="con_email" required="" />
                            </div>
                          </div>
                          <div class="form-group required second-child">
                            <div class="col-sm-12 p-0">
                              <label class="control-label">Share your opinion</label>
                              <textarea class="review-textarea" name="con_message" id="con_message"></textarea>
                              <div class="help-block">
                                <span class="text-danger">Note:</span> HTML is not translated!
                              </div>
                            </div>
                          </div>
                          <div class="form-group last-child required">
                            <div class="col-sm-12 p-0"></div>
                            <div class="torress-btn-ps_right">
                              <a href="javascript:void(0)" class="torress-btn torress-btn_dark">Continue</a>
                            </div>
                          </div>
                        </template>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else></div>
  </div>
</template>
<script>
  // import Vue from 'vue'
  import zoomOnHover from '@/components/zoomImage'
  import pageComponent from '@/components/page'
  import {
    goodsDetail,
    addCart,
    commentList
  } from '@/api'
  import {
    mapGetters
  } from 'vuex'
  // require styles
  export default {
    name: 'home',
    components: {
      zoomOnHover,
      pageComponent
    },
    data() {
      return {
        bigimg: false,
        peic: '',
        num: '',
        data: {},
        loading: 0,
        quantity: 0,
        batchNum: 0,
        base: 0,
        size: ['S', 'M', 'L', 'XL'],
        sizeIndex: 0,
        swiperOption: {
          slidesPerView: 2,
          spaceBetween: 10,
          // autoplay: true,
          // loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
          // some swiper options/callbacks
          // 所有的参数同 swiper 官方 api 参数
          // ...
        },
        picsActiveIndex: 0
      }
    },
    computed: {
      swiper() {
        if (this.$refs.mySwiper) {
          this.swiper.el.onmouseover = function () {
            this.swiper.navigation.$nextEl.removeClass('hide')
            this.swiper.navigation.$prevEl.removeClass('hide')
          }
          this.swiper.el.onmouseout = function () {
            this.swiper.navigation.$nextEl.addClass('hide')
            this.swiper.navigation.$prevEl.addClass('hide')
          }
          return this.$refs.mySwiper.swiper
        } else {
          return false
        }
      },
      skus() {
        let data = this.data
        if (data.skus) {
          return data.skus[0]
        } else {
          return {}
        }
      },
      zoomImage() {
        return this.data.pics[this.picsActiveIndex] || this.data.cover
      },
      comments() {
        return (this.data.comments && this.data.comments.list) || []
      },
      ...mapGetters(['token', 'carts']),
      pageConfigTotal() {
        return {}
        // let comment = this.data.comments
        // return {
        //   total: comment.total,
        //   pageSize: comment.pageLimit,
        //   pageNo: comment.page
        // }
      }
    },
    watch: {
      $route() {
        this.onLoad()
      },
      carts() {
        this.loadQuantity()
      }
      // loading(e) {
      //   if (e === 1) {
      //     this.loadQuantity()
      //   }
      // },
    },
    created() {
      this.onLoad()
      this.peic = window.localStorage.getItem('price')
      this.num = window.localStorage.getItem('num')
    },
    mounted() {},
    methods: {
      zxby(value) {
        return value.split('@@')[1] || value
      },
      onLoad() {
        this.$store.dispatch('setAppLoading', true)
        let id = this.$route.params.id
        let params = {
          spuId: id
        }

        goodsDetail(params).then(res => {

          this.$store.dispatch('setAppLoading', false)
          if (res.data.data) {

            this.data = res.data.data
            this.batchNum = 0
            this.base =  Number(this.data.wholesaleNum)
            this.batchNum = this.data.cart.batchNum
            this.quantity = this.data.cart.num
            this.loading = 1
          }
          // this.loadQuantity()
        })
      },
      loadQuantity() {
        let carts = this.carts
        let skus = this.skus
        if (carts && skus) {
          let me = carts.find(e => e.skuId == skus.skuId)
          if (me) {
            // if (me.isWholesale== 1) {
            //   this.batchNum = me.num
            // } else {
            //   this.quantity = me.num
            // }
            
          }
        }
      },
      changePage(e) {
        commentList({
          spu_id: this.skus.skuId,
          page: e,
          pageLimit: this.comments.pageLimit
        }).then(res => {
          if (res.data.data && res.data.data.list) {
            this.comments.list.push(res.data.data.list)
          } else {
            this.$toasted.error(res.data.msg || this.$i18n.ERROR_NONE)
          }
        })
      },
      addCart() {
        if (!this.token) {
          this.$store.dispatch('openLoginBox', true)
          return
        }
        let params = {
          skuId: this.skus.skuId,
          // num: this.quantity,
          // batch_num: this.batchNum
        }
        if (this.batchNum != 0) {
          params.batch_num =this.batchNum
        } if (this.quantity != 0) {
          params.num = this.quantity
        }
        addCart(params).then(res => {
          if (res.data.status === 'fail') {
            this.$toasted.error(res.data.msg)
          } else {
            this.$store.dispatch('onChangeCart')
            this.$toasted.error(this.$i18n.ADD_CART_SUCCESS)
          }
        })
      },
      changeTabs(e) {
        let el = e.currentTarget
        let id = el.dataset.href

        let elSiblings = [...el.parentNode.parentNode.children].filter(child => {
          return child.children[0] !== el
        })
        el.classList.add('active')
        elSiblings[0].children[0].classList.remove('active')

        let dom = document.querySelector(id)
        dom.classList.add('active')
        dom.classList.add('show')
        let siblings = [...dom.parentNode.children].filter(child => child !== dom)
        siblings[0].classList.remove('active')
        siblings[0].classList.remove('show')
      },
      quantityChange(data) {
        let max = this.skus.stock

        if (data) {
          if (this.quantity < max) {
            this.quantity++
          }
        } else {
          let q = this.quantity - 1
          if (q < 1) {
            q = 0
          }
          this.quantity = q
        }
      },
      batchNumChange(data) {
        let max = this.skus.stock
        if (data) {
          if (this.batchNum < max) {
            if (this.batchNum == 1) {
              this.batchNum = 0
            }
            this.batchNum=this.base+ this.batchNum
          }
        } else {
          let q = this.batchNum - this.base
          if (q < 1) {
            q = 0
          }
          this.batchNum = q
        }
      },
      changeImage(idx) {
        this.picsActiveIndex = idx
      },
      openSelect(e) {
        let el = e.currentTarget
        el.classList.toggle('open')
      },
      changeSelect(idx) {
        this.sizeIndex = idx
      },


    }
  }

</script>
<style lang="less">
  #detail {

    .swiper-container {
      padding: 0 30px;
      margin-top: 30px;

      .swiper-button-prev,
      .swiper-button-next {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 766px) and(min-width: 577px) {
      .swiper-container {
        padding: 0;

        .swiper-button-prev,
        .swiper-button-next {
          transform: scale(0.5) translateY(-50%);
          transform-origin: top;
        }

        .swiper-slide {
          padding: 5px;

          img {
            // width: 73px;
            // height: 73px;
          }
        }
      }
    }

    @media screen and (max-width: 576px) {
      .swiper-container {
        .swiper-slide {
          img {
            max-width: 73px;
          }
        }
      }
    }

    .swiper-slide {
      box-sizing: border-box;
      padding: 0 10px;
      outline: none;
      border: 1px solid #ebebeb;
      // margin: 0 10px;
      background-color: transparent;
      text-align: center;

      img {
        display: inline-block;
        width: 100%;
      }
    }
  }

  @border-color: #ebebeb;
  @body-text_color: #333333;
  @primary-color: #fed100;
  @bg-white_color: #fff;
  @bg-nobel_color: #999999;
  @heading-color: #333;

  @media (min-width: 992px) {
    #detail {
      .sp-tab_area {
        padding-top: 30px;
      }
    }

    .sp-area {

      // padding: 35px 0 0 !important;
      .sp-nav {
        // padding-top: 25px !important;
      }
    }
  }

  @media (max-width: 567px) {
    .zoompro-border {
      img {
        width: auto !important;
        height: 240px !important;
        margin: 0 auto;
      }
    }
  }

  .sp-area {
    //padding: 65px 0 0;
    padding: 0 0 0;

    .sp-nav {
      background-color: @bg-white_color;
      //padding-top: 25px;
      padding-top: 0;

      img {
        max-width: 100%;
      }

      .sp-img_slider {
        padding: 0 30px;
        margin-top: 30px;

        .slick-list {
          margin: 0 -15px;

          .slick-slide {
            border: 1px solid @border-color;
            margin: 0 15px;

            img {
              width: 100%;
            }
          }
        }
      }

      .sp-content {
        .sp-heading {
          >h2 {
            margin-bottom: 0;
            padding-bottom: 25px;
            font-size: 28px;
            font-weight: 400;
          }
        }

        .price-box {
          padding-bottom: 15px;

          .old-price {
            display: inline-block;

            font-size: 19px;
          }

          .new-price {
            font-size: 31px;
            color: inherit;
            font-weight: 600;
          }
        }

        .product-desc {
          >p {
            margin-bottom: 0;
          }
        }

        .torress-countdown_area {
          display: flex;
          justify-content: flex-start;
          padding-top: 30px;

          .torress-countdown {
            width: 50%;
          }
        }

        .product-size_box {
          display: flex;
          align-items: center;
          padding-top: 25px;

          >span {
            padding-right: 15px;
          }

          .myniceselect {
            &.nice-select {
              >span {
                height: 40px;
                line-height: 40px;
                padding: 0 30px;
              }
            }
          }
        }

        .color-list_area {
          margin-top: 25px;
        }

        .in-stock {
          padding-top: 25px;

          >i {
            padding-right: 5px;
            color: #43ae2e;
          }
        }

        .quantity {
          padding-top: 25px;
          display: flex;
          align-items: center;

          .cart-plus-minus {
            margin: 0;
            display: inline-block;
            margin-left: 15px;
            margin-right: 15px;
          }

         
        }
        .additional-btn_area {
            // padding-left: 15px;
            margin-top: 35px;
            .additional_btn {
              cursor: pointer;
              border: 2px solid @primary-color;
              border-radius: 25px;
              font-weight: 700;
              width: 170px;
              padding: 0 10px;
              box-sizing: content-box;
              height: 45px;
              line-height: 43px;
              text-align: center;
              display: block;
              font-size: 16px;

              &:hover {
                background-color: @primary-color;
                color: @bg-white_color  !important;
              }
            }
          }
        .qty-btn_area {
          padding: 30px 0;
          border-bottom: 1px solid @border-color;

          >ul {
            li {
              display: inline-block;
              padding-left: 5px;

              &:first-child {
                padding-left: 0;
              }

              >a {
                &.qty-btn {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                }

                >i {
                  background-color: @primary-color;
                  display: block;
                  margin-right: 10px;
                  font-size: 16px;
                  width: 35px;
                  height: 35px;
                  line-height: 36px;
                  text-align: center;
                  border-radius: 100%;
                }

                &:hover {
                  >i {
                    color: @bg-white_color  !important;
                    animation: 2000ms ease-in-out 0.3s normal none 1 running flipInY;
                  }
                }
              }
            }
          }
        }

        .category-list_area {
          display: flex;
          align-items: flex-start;
          padding-top: 25px;

          >h6 {
            margin-bottom: 0;
          }

          ul {
            padding-left: 5px;

            >li {
              display: inline-block;

              >a {
                color: @bg-nobel_color;
              }
            }
          }

          &.tag-list_area {
            padding-top: 20px;
          }
        }

        .torress-social_link {
          border-top: 1px solid @border-color;
          padding-top: 30px;
          margin-top: 25px;
          display: flex;
          align-items: center;

          >h6 {
            font-size: 14px;
            margin-bottom: 0;
            padding-right: 15px;
          }

          ul {
            >li {
              >a {
                border: 1px solid @border-color;
              }
            }
          }
        }
      }
    }
  }

  .sp-gallery_area {
    .sp-nav {
      padding-top: 30px;

      .sp-gallery {
        .lg-image {
          padding: 5px;
        }

        >.row {
          &:first-child {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  /* ---Single Product Tab Style--- */
  .sp-img_area {
    .zoompro-border {
      border: 1px solid @border-color;
      cursor: all-scroll;
    }
  }

  /* ---Single Product Sticky--- */
  .sp-sticky_area {
    .sp-sticky_gallery {
      padding-left: 0 !important;

      >.row {
        &:first-child {
          margin-bottom: 0 !important;
        }

        [class*='col-'] {
          .lg-image {
            margin-bottom: 30px;
          }
        }

        &:last-child {
          [class*='col-'] {
            &:last-child {
              .lg-image {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  /* ---Single Product Tab Area--- */
  .sp-tab_area {
    padding-top: 55px;
    border-bottom: 0;

    .product-tab {
      .product-menu {
        >li {
          >a {
            cursor: pointer;
            font-weight: 400;
            color: @bg-nobel_color;
            padding: 0 30px 10px;
            position: relative;
            display: block;
            font-size: 22px;

            &:before {
              transition: all 0.2s ease-in-out;
              content: '';
              background: @heading-color;
              height: 1px;
              width: 0;
              position: absolute;
              bottom: -1px;
              left: 0;
              opacity: 0;
              visibility: hidden;
            }

            &.active {
              color: @heading-color;

              &:before {
                width: 100%;
                opacity: 1;
                visibility: visible;
              }
            }
          }

          &:hover {
            >a {
              color: @heading-color  !important;

              &:before {
                opacity: 1;
                visibility: visible;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .tab-content {
      border-top: 1px solid @border-color;
      border-bottom: 1px solid @border-color;
      padding: 25px 0;

      .product-description {
        >p {
          margin-bottom: 0;
          padding-top: 0px;

          &.additional-desc {
            padding-top: 15px;
          }
        }
      }

      .additional-information-inner_stuff {
        margin-bottom: 0;
      }

      .form-horizontal {
        .table-striped {
          margin-bottom: 25px;

          >tbody {
            tr {
              background-color: @bg-white_color;

              &:nth-child(2) {
                td {
                  >p {
                    margin-bottom: 0;
                    padding-bottom: 20px;
                  }
                }
              }

              td {
                padding: 20px;
              }
            }
          }
        }

        h2 {
          margin-bottom: 0;
          padding-bottom: 15px;
        }

        .form-group {
          margin-bottom: 0;
          padding-bottom: 15px;

          &.second-child {
            padding-bottom: 10px;
          }

          &.last-child {
            padding-bottom: 0;
          }

          .review-input,
          .review-textarea {
            border: 1px solid @border-color;
            width: 100%;
            height: 30px;
            color: #888888;

            &.review-textarea {
              height: 120px;
            }
          }

          label {
            margin-bottom: 0;
            padding-bottom: 18px;
          }

          .help-block {
            padding-top: 18px;
          }

          .torress-btn-ps_right {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  /* ---Single Product Slider Area--- */
  .sp-product-slider_area {
    padding: 40px 0 60px;
  }

  //圖片大小
  // .fade-enter-active,
  // .fade-leave-active {
  // transition: all .2s linear;
  // transform: translate3D(0, 0, 0);
  // }
  // .fade-enter,
  // 　　.fade-leave-active {
  // transform: translate3D(100%, 0, 0);
  // }

  /* bigimg */
  .img-view {
    position: inherit;
    width: 100%;
    height: 100%;
  }

  /*遮罩层样式*/
  .img-view .img-layer {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /*不限制图片大小，实现居中*/
  .img-view .img img {
    // max-width: 100%;
    // max-height: 100%;
    display: block;
    position: absolute;
    left: 10%;
    top: -20%;
    margin: auto;
    z-index: 1000;
  }

</style>
