<template>
  <div >
    <div
      class="zoom-on-hover"
      @mousemove="move"
      @mouseenter="zoom"
      @mouseleave="unzoom"
      @click="big"
    >
      <img class="normal" ref="normal" :src="imgNormal" />
      <img class="zoom" ref="zoom" :src="imgZoom || imgNormal" />
    </div>
    <transition name="fade" >
      <div class="img-view" @click="small" v-if="bigimg" >
        <!-- 遮罩层 -->
        <div class="img-layer"></div>
        <div class="img" >
          <img :src="imgNormal" />
        </div>
      </div>
    </transition>
  </div>
</template>
<style  >
.zoom-on-hover {
  position: relative;

  overflow: hidden;
}

.zoom-on-hover .normal {
  width: 100%;
}

.zoom-on-hover .zoom {
  position: absolute;
  opacity: 0;
  transform-origin: top left;
}

/* .fade-enter-active,
.fade-leave-active {
  transition: all 0.2s linear;
  transform: translate3D(0, 0, 0);
}
.fade-enter,
.fade-leave-active {
  transform: translate3D(100%, 0, 0);
} */

.img-view {
  position: inherit;
  width: 100%;
  height: 100%;
  
}

.img-view .img-layer {
  position: fixed;
  z-index: 999;
  top: 10%;
  left: -20%;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.img-view .img img {
  width: 180%;
  display: block;
  position: absolute;
  left: 0;
  top: -20%;
  margin: auto;
  z-index: 1000;
}
</style>
<script>
function pageOffset(el) {
  // -> {x: number, y: number}
  // get the left and top offset of a dom block element
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    y: rect.top + scrollTop,
    x: rect.left + scrollLeft,
  };
}

export default {
  name: "zoomOnHover",
  props: ["imgNormal", "imgZoom", "scale", "disabled"],
  data() {
    return {
      scaleFactor: 1,
      resizeCheckInterval: null,
      bigimg: false,
    };
  },
  methods: {
    zoom: function () {
      if (this.disabled) return;
      this.$refs.zoom.style.opacity = 1;
      this.$refs.normal.style.opacity = 0;
    },
    unzoom: function () {
      if (this.disabled) return;
      this.$refs.zoom.style.opacity = 0;
      this.$refs.normal.style.opacity = 1;
    },
    move: function (event) {
      if (this.disabled) return;
      var offset = pageOffset(this.$el);
      var zoom = this.$refs.zoom;
      var normal = this.$refs.normal;
      var relativeX = event.clientX - offset.x + window.pageXOffset;
      var relativeY = event.clientY - offset.y + window.pageYOffset;
      var normalFactorX = relativeX / normal.offsetWidth;
      var normalFactorY = relativeY / normal.offsetHeight;
      var x =
        normalFactorX *
        (zoom.offsetWidth * this.scaleFactor - normal.offsetWidth);
      var y =
        normalFactorY *
        (zoom.offsetHeight * this.scaleFactor - normal.offsetHeight);
      zoom.style.left = -x + "px";
      zoom.style.top = -y + "px";
    },
    big() {
      this.bigimg = true;
    },
    small() {
      this.bigimg = false;
    },
    initEventLoaded: function () {
      // emit the "loaded" event if all images have been loaded
      var promises = [this.$refs.zoom, this.$refs.normal].map(function (image) {
        return new Promise(function (resolve, reject) {
          image.addEventListener("load", resolve);
          image.addEventListener("error", reject);
        });
      });
      var component = this;
      Promise.all(promises).then(function () {
        component.$emit("loaded");
      });
    },
    initEventResized: function () {
      var normal = this.$refs.normal;
      var previousWidth = normal.offsetWidth;
      var previousHeight = normal.offsetHeight;
      var component = this;
      this.resizeCheckInterval = setInterval(function () {
        if (
          previousWidth != normal.offsetWidth ||
          previousHeight != normal.offsetHeight
        ) {
          previousWidth = normal.offsetWidth;
          previousHeight = normal.offsetHeight;
          component.$emit("resized", {
            width: normal.width,
            height: normal.height,
            fullWidth: normal.naturalWidth,
            fullHeight: normal.naturalHeight,
          });
        }
      }, 1000);
    },
  },
  mounted: function () {
    if (this.$props.scale) {
      this.scaleFactor = parseInt(this.$props.scale);
      this.$refs.zoom.style.transform = "scale(" + this.scaleFactor + ")";
    }
    this.initEventLoaded();
    this.initEventResized();
  },
  updated: function () {
    this.initEventLoaded();
  },
  beforeDestroy: function () {
    this.resizeCheckInterval && clearInterval(this.resizeCheckInterval);
  },
};
</script>